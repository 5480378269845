@import "src/styles/common.scss";

.slider-wrapper {
    width: 100%;
}

.slider-title {
    font-size: 28px;
    margin: 20px 0px;
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
    white-space: pre;
}

.slider-value {
    font-size: 40px;
    margin-bottom: 15px;
    color: red;
}

.slidecontainer {
    width: 100%;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    max-width: 506px;
    height: 20px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: black;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4caf50;
    cursor: pointer;
}

.slider-explain {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
}

.currency {
    font-weight: bold;
}
