@import "src/styles/common.scss";

.navbar-container {
    font-family: "Gotham Rounded Bold", sans-serif;
    display: flex;
    justify-content: center;
    position: fixed;
    align-items: center;
    width: 100%;
    transition: 1s;
    z-index: 1000;
    background-color: var(--navbar-background);
    flex-wrap: wrap;

    .container {
        padding: 0;
    }

    &_background {
        display: flex;
        height: 80px;
        max-width: 1920px;
        width: 100%;
        justify-content: space-between;

        &_none {
            background-color: transparent;
        }
        .navbar-logo-wrapper {
            display: flex;
            align-items: center;
            width: 30%;
        }
        .navbar-logo-container {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;

            &_logo {
                height: 50px;
            }

            &_text {
                margin-left: 5px;
                height: 40px;
            }
        }
        .navbar-links-container {
            display: flex;
            font-size: 20px;
            width: 70%;
            justify-content: space-around;
        }
    }
}
.navbar_socials {
    height: 50px;
    background-color: #334195;
    width: 100vw;
    position: absolute;
    left: 0;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    display: flex;
    justify-content: center;
    align-items: center;
    &-icon {
        width: 20px;
        margin: 0px 15px;
    }
}

.hamburger-menu {
    display: none;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.active-link {
    color: #49ccea;
}

@media only screen and (max-width: $desktop) {
    .navbar-container .navbar-links-container {
        display: none;
    }

    .navbar-container_background {
        margin: 0px 20px;
    }

    .navbar-container .navbar-logo-container {
        width: auto;
    }

    .hamburger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &_icon {
            height: 40px;
        }
    }

    .hamburger-menu-dropdown {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 80px;
        min-width: 100%;
        font-size: 20px;
        height: 50%;
        z-index: 1000;
        justify-content: flex-end;
        background-color: #344295;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: $tablet) {
    .navbar-container {
        font-family: "Gotham Rounded Book", sans-serif;
        &_background {
            .navbar-logo-wrapper {
                width: 40%;
            }
            .navbar-logo-container {
                &_logo {
                    height: 40px;
                }
                &_text {
                    height: 30px;
                }
            }
        }
    }
    .container {
        width: 100%;
    }


    .navbar-container_background {
        margin: 0;
    }
}
