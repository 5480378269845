@import "src/styles/common.scss";

.purchase-nft {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_sold-title {
        font-weight: bold;
        font-size: 44px;
        margin-bottom: 20px;
        text-align: center;
    }

    &_balance {
        font-weight: bold;
        font-size: 30px;
    }

    &_purchase {
        display: flex;
        width: 75%;
        padding-top: 100px;
    }

    &_image-container {
        display: flex;
        flex: 50% 1;
        align-items: center;
        justify-content: center;
    }

    &_image {
        max-height: 450px;
        padding-bottom: 30px;
    }

    &_purchase-container {
        display: flex;
        flex: 50%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: $laptop) {
    .purchase-nft {
        &_purchase {
            flex-direction: column;
        }

        &_image-container {
            margin: 0;
        }

        &_purchase-container {
            margin: 0;
        }
    }
}
