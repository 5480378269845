@import "src/styles/common.scss";

.button-container {
    display: flex;
}

.button {
    height: 58px;
    cursor: pointer;
    color: #334195;
    border: 2px solid #334195;
    width: 240px;
    font-size: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}

.button:hover {
    background-color: #def6fb;
    transition: 1s;
    border-color: #70c1e5;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .button {
        font-size: 20px;
    }
}
