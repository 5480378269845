@import "src/styles/common.scss";

.about-fdm {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    &_content {
        display: flex;
    }
    &_title {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Gotham Rounded Bold", sans-serif;
    }

    &_description {
        padding-top: 50px;
        flex: 100%;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }

    &_slider {
        display: flex;
        justify-content: center;
        flex: 30%;
        align-items: center;
        margin: 0 50px;
    }

    &_wrapper {
        display: flex;
        padding-top: 50px;
        align-items: center;
    }

    &_donate {
        display: flex;
        flex: 50% 1;
        align-items: center;
        height: 100%;
        display: flex;
        margin-right: 50px;
        filter: grayscale(1);
    }

    &_child {
        object-fit: cover;
        height: 300px;
    }

    &_list {
        display: flex;
        flex: 50%;
    }
}

ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
}
ol li {
    counter-increment: my-awesome-counter;
    display: flex;
    font-size: 16px;
    margin-bottom: 0.5rem;
    min-height: 100px;
    align-items: center;
}
ol li::before {
    content: "0" counter(my-awesome-counter);
    font-weight: bold;
    font-size: 4rem;
    margin-right: 0.5rem;
    font-family: "Abril Fatface", serif;
    line-height: 1;
    opacity: 0.1;
}

@media screen and (max-width: $laptop) {
    .about-fdm {
        height: 100%;
        padding-top: 50px;
        display: flex;

        &_content {
            flex: 100%;
            flex-direction: column;
        }

        &_description {
            min-height: 600px;
        }

        &_slider {
            padding-top: 50px;
        }

        &_donate {
            margin: 0;
            margin: 20px 0;
        }

        &_wrapper {
            flex-direction: column;
        }
    }

    ol li {
        min-height: 150px;
    }
}
