.footer-container {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 2;
    background-color: #fafafa;
    font-size: 16px;
    text-align: center;
}
