@import "src/styles/common.scss";

.team {
    &_container {
        padding-top: 100px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &_card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        width: 23%;
        border-radius: 5px;
        filter: grayscale(1);
        cursor: pointer;
        margin: 25px;
        &:hover {
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            filter: grayscale(0);
        }
    }

    &_image {
        border-radius: 5px 5px 0 0;
        width: 100%;
        object-fit: cover;
        height: 300px;
    }

    &_text {
        display: flex;
        flex-direction: column;
        &-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        &-name {
            margin: 15px;
            font-size: 14px;
            font-family: "Gotham Rounded Bold", sans-serif;
            text-align: center;
        }
        &-title {
            margin: 0px 20px 0px 20px;
            font-size: 12px;
            font-style: italic;
            display: flex;
            min-height: 50px;
            justify-content: center;
        }

        &-description {
            margin: 0px 20px 15px 20px;
            font-size: 13px;
            align-items: baseline;
            display: flex;
            min-height: 70px;
        }
    }
}

@media screen and (max-width: $desktop) {
    .team {
        &_card {
            width: 60%;
        }
    }
}

@media screen and (max-width: $mobile) {
    .team {
        &_card {
            width: 100%;
        }
    }
}
