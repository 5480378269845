@import "src/styles/common.scss";

.roadmap {
    &_wrapper {
        display: flex;
    }

    &_block {
        display: flex;
        flex: 25%;
        flex-direction: column;
        text-align: center;
    }
    &_logo-on {
        height: 150px;
        margin-top: 50px;
        margin-bottom: 50px;
        animation: float-banana 6s infinite;
    }

    &_logo-off {
        height: 150px;
        margin-top: 50px;
        margin-bottom: 50px;
        filter: grayscale(1);
        &:hover {
            -webkit-animation: shake-lr 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
            animation: shake-lr 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }
    }

    &_title {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-family: "Gotham Rounded Bold", sans-serif;
    }

    &_description {
        margin-top: 30px;
        font-size: 18px;
        text-align: center;
        padding: 0 15px;
    }
}

@keyframes float-banana {
    0% {
        transform: translatey(-15px);
    }
    50% {
        transform: translatey(15px);
    }
    100% {
        transform: translatey(-15px);
    }
}

@-webkit-keyframes shake-lr {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}
@keyframes shake-lr {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $desktop) {
    .roadmap {
        &_wrapper {
            flex-direction: column;
        }
    }
}
