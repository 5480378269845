$mobile: 420px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;

.container {
    margin: auto;
    padding: 75px 0;
}
@media only screen and (min-width: $tablet) {
    .container {
        width: 750px;
    }
}
@media only screen and (min-width: $laptop) {
    .container {
        width: 970px;
    }
}
@media only screen and (min-width: $desktop) {
    .container {
        width: 80%;
    }
}

img {
    max-width: 100%;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
    color: var(--theme-page-text);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    &:hover {
        color: var(--theme-link-hover-color);
        border-radius: 10px;
        transition: 1s;
    }
}

h2 {
    font-family: "Gotham Rounded Bold", sans-serif;
    width: 100%;
    font-size: 34px;
    text-align: center;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
}

@media screen and (max-width: $mobile), screen and (max-width: $laptop) {
    a {
        justify-content: center;
        color: white;
    }
}
@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    a {
        justify-content: center;
        color: white;
    }
}
