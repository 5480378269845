@import "src/styles/common.scss";

.about-kites {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    flex-direction: column;
    &_content {
        display: flex;
        padding-top: 100px;
    }
    &_title {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Gotham Rounded Bold", sans-serif;
    }

    &_description {
        flex: 70%;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 400px;
    }

    &_slider {
        display: flex;
        justify-content: center;
        flex: 30%;
        align-items: center;
        margin: 0 50px;
    }
}

@media screen and (max-width: $laptop) {
    .about-kites {
        height: 100%;
        padding-top: 50px;
        display: flex;

        &_content {
            flex: 100%;
            flex-direction: column;
        }

        &_description {
            min-height: 900px;
        }

        &_slider {
            padding-top: 50px;
        }
    }
}
