@import "src/styles/common.scss";

.slider {
    max-width: 300px;
    max-height: 410px;
}

.slider img {
    width: 100%;
    max-width: 300px;
    height: auto;
}

.awssld__wrapper {
    height: 410px;
}

.awssld__container {
    height: 230px;
}

.awssld__bar {
    background-color: transparent;
}

.awssld__content {
    background-color: transparent;
}

.awssld__next {
    right: -50px;
}
.awssld__prev {
    left: -50px;
}

.awssld__timer {
    background-color: transparent;
}

@media screen and (max-width: $mobile) {
    .slider {
        max-width: 200px;
        max-height: 280px;
    }
}
