.light {
    --navbar-background: #ffffff;
    --theme-page-text: #000000;
    --theme-link-hover-color: #49ccea;
}

.dark {
    --navbar-background: #000000;
    --theme-page-text: #ffffff;
    --theme-link-hover-color: #49ccea;
}
