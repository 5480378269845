@import "src/styles/common.scss";

.notification-container-success {
    position: fixed;
    top: 0;
    right: 0;
    height: 75px;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    flex-direction: column;
    padding: 20px;
    opacity: 0.9;
    margin-right: 20px;
    margin-top: 20px;
    color: white;
    background-color: #28a745;
    border-left: 20px solid #1a6a2d;
    -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.notification-container-error {
    position: fixed;
    top: 0;
    right: 0;
    height: 75px;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    flex-direction: column;
    padding: 20px;
    opacity: 0.9;
    margin-right: 20px;
    margin-top: 20px;
    background-color: #dc3646;
    border-left: 20px solid #a71e2b;
    -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.notification-container-x {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 16px;
}

.notification-title {
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-weight: bold;
    margin-bottom: 10px;
}

.notification-description {
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .notification-container-success {
        width: 30%;
    }

    .notification-container-error {
        width: 30%;
    }
}
