@import "src/styles/common.scss";

.welcome-to-fdm {
    display: flex;
    height: 600px;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

    &_description {
        font-family: "Gotham Rounded Bold", sans-serif;
        font-size: 20px;
        margin: 50px 0 30px 0;
    }
}
.fdm-icons {
    &_container {
        display: flex;
        justify-content: space-between;
    }
    &_icon-block {
        display: flex;
        flex-direction: column;
        flex: 25%;
        font-size: 18px;
    }

    &_icon {
        max-height: 150px;
        margin: 30px 0;
        &:hover {
            -webkit-animation: rotate-center 0.6s ease-in-out both;
            animation: rotate-center 0.6s ease-in-out both;
        }
    }

    &_text {
        padding: 0 15px;
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: $laptop) {
    .welcome-to-fdm {
        height: 100%;
        padding-top: 50px;
    }

    .fdm-icons {
        &_container {
            flex-direction: column;
        }
    }
}
