@import "src/styles/common.scss";

.partners {
    &_container {
        padding-top: 100px;
        display: flex;
        justify-content: space-evenly;
    }

    &_image {
        width: 100%;
        max-width: 250px;
        max-height: 125px;
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
        }
        &-container {
            justify-content: center;
        }
    }
}

@media screen and (max-width: $desktop) {
    .partners {
        &_container {
            flex-direction: column;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &_image {
            width: 100%;
            margin: 60px 0px;
        }
    }
}
