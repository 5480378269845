@import "src/styles/common.scss";

.modal {
    &_backdrop {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1000;
    }

    &_container {
        z-index: 1001;
        max-height: 60%;
        max-width: 60%;
        padding: 40px;
        color: white;
        background-color: #334195;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        &-x {
            position: absolute;
            top: 25px;
            right: 38px;
            cursor: pointer;
        }
    }
    &_form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        width: 100%;
    }

    &_label {
        display: flex;
        width: 100%;
        justify-content: space-between;
        &-title {
            display: flex;
            flex: 30%;
            align-items: center;
        }
    }

    &_input {
        width: 100%;
        border: 2px solid #ccc;
        height: 30px;
        padding: 0 0 0 20px;
        border-radius: 10px;
    }

    &_button {
        height: 58px;
        cursor: pointer;
        border: 2px solid white;
        width: 240px;
        font-size: 16px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        background: transparent;
        color: white;
        &:hover {
            background-color: #def6fb;
            transition: 1s;
            border-color: #70c1e5;
            color: black;
        }
    }

    &_textarea {
        width: 100%;
        height: 150px;
        padding: 12px 20px;
        border: 2px solid #ccc;
        background-color: #f8f8f8;
        padding: 20px 0 0 20px;
        border-radius: 10px;
    }
}

@media screen and (max-width: $desktop) {
    .modal {
        &_container {
            max-width: 75%;
            width: 75%;
            max-height: 80%;
        }
        &_button {
            font-size: 20px;
        }

        &_label {
            align-items: center;
            flex-direction: column;
        }
    }
}
