@import "src/styles/common.scss";

.container {
    padding-top: 200px;
}

.header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 550px;

    &_title-block {
        display: flex;
        flex: 20%;
        justify-content: space-around;
        align-items: center;
        height: 50%;
        flex-direction: column;
        &-bold {
            font-family: "Gotham Rounded Bold", sans-serif;
        }
    }

    &_description-block {
        font-family: "Gotham Rounded Bold", sans-serif;
        display: flex;
        flex-direction: column;
        &-text {
            font-size: 16px;
        }

        &-title-1 {
            color: #ec259d;
        }

        &-title-2 {
            color: #49ccea;
        }
    }

    &_details {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &_images {
        display: flex;
        flex: 50%;
        height: 100%;
        justify-content: center;
        position: relative;
    }

    &_buttons-block {
        font-family: "Gotham Rounded Bold", sans-serif;
        display: flex;
        flex: 20%;
        justify-content: center;
        flex-direction: column;
        height: 40%;
        justify-content: space-between;
        &-title {
            font-size: 20px;
        }
        &-description {
            color: #334195;
        }
    }

    &_kite {
        position: absolute;
        width: 50%;
        max-width: 325px;
        height: auto;
        &:nth-child(1) {
            transform: rotate(-25deg);
            top: 50px;
            transition: 1s;
            left: -35%;
            right: 0;
            margin: auto;
            &:hover {
                transform: rotate(-25deg) translateY(-20px);
            }
        }
        &:nth-child(2) {
            z-index: 1;
            transform: rotate(-5deg);
            transition: 1s;
            top: 40px;
            left: 0;
            right: 0;
            margin: auto;
            &:hover {
                transform: rotate(-5deg) translateY(-20px);
            }
        }
        &:nth-child(3) {
            z-index: 2;
            transform: rotate(15deg);
            top: 80px;
            left: 35%;
            right: 0;
            margin: auto;
            transition: 1s;
            &:hover {
                transform: rotate(15deg) translateY(-20px);
            }
        }
    }
}

@keyframes float-banana {
    0% {
        transform: translatey(-15px);
    }
    50% {
        transform: translatey(15px);
    }
    100% {
        transform: translatey(-15px);
    }
}

@media screen and (max-width: $laptop) {
    .header-container {
        height: 100%;
        &_images {
            flex: 100%;
            margin: 0;
            height: 500px;
        }

        &_buttons-block {
            flex: 100%;
        }

        &_details {
            justify-content: space-evenly;
        }

        &_title-block {
            text-align: center;
        }

        &_description-block {
            flex: 50%;
        }

        &_buttons-block {
            padding-top: 50px;
            height: 300px;
            align-items: center;
        }
    }
}

@media screen and (max-width: $mobile) {
    .header-container {
        &_images {
            height: 400px;
        }

        &_buttons-block {
            padding-top: 0px;
            height: 300px;
        }
    }
}
